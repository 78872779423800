export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.params.servicePointUri && to.params.deviceUri) {
    const { servicePointUri, deviceUri } = to.params;
    const repairServices = await getDeviceRepairServices(
      servicePointUri as string,
      deviceUri as string
    );

    if (!repairServices || repairServices.length === 0) {
      return navigateTo('/not-found');
    }

    const repairServicesState = useState(
      'repairServicesByDeviceUri',
      () => repairServices
    );

    repairServicesState.value = repairServices;
  }
});
